import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {ProductVariant} from '../../../state/product.model';
import {ProductsQuery} from '../../../state/products.query';
import {SupplierProduct} from '../../../state/supplier-products/supplier-product.model';
import {SupplierProductsQuery} from '../../../state/supplier-products/supplier-products.query';
import {SupplierProductsService} from '../../../state/supplier-products/supplier-products.service';
import {SelectVariantForShoppingCartDialogData} from './types/select-variant-for-shopping-cart-dialog-data';
import {
  SelectVariantForShoppingCartDialogOutputData
} from './types/select-variant-for-shopping-cart-dialog-output-data';

@Component({
  selector: 'app-select-variant-for-shopping-cart-dialog',
  templateUrl: './select-variant-for-shopping-cart-dialog.component.html',
  styleUrls: ['./select-variant-for-shopping-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectVariantForShoppingCartDialogComponent implements OnInit {
  readonly supplierProductsQuery = inject(SupplierProductsQuery);
  readonly productsQuery = inject(ProductsQuery);
  readonly data: SelectVariantForShoppingCartDialogData = inject(MAT_DIALOG_DATA);

  private readonly matDialogRef = inject(MatDialogRef<SelectVariantForShoppingCartDialogComponent, SelectVariantForShoppingCartDialogOutputData>);
  private readonly supplierProductsService = inject(SupplierProductsService);

  selectedVariant: ProductVariant | null = null;

  get initialSelection() {
    if (!this.data.variants || !this.data.selectedVariantId) {
      return null;
    }

    return this.data.variants.find(variant => variant.id === this.data.selectedVariantId);
  }

  ngOnInit() {
    if (this.initialSelection) {
      this.selectVariant(this.initialSelection);
    }
  }

  addToShoppingCart(supplierProduct: SupplierProduct) {
    this.matDialogRef.close({supplierProduct} as SelectVariantForShoppingCartDialogOutputData);
  }

  cancel() {
    this.matDialogRef.close();
  }

  selectVariant(variant: ProductVariant) {
    this.selectedVariant = variant;
    this.supplierProductsService.getForProduct(variant.id).subscribe();
  }
}
