<div
  class="gallery-item">
    <div class="flex gap-2">
        <div class="w-[50%] supplier-product-image-container">
            <div class="supplier-product-image">
                @if (rank) {
                    <button
                      type="button"
                      mat-mini-fab
                      class="supplier-product-rank-icon">
                        #{{ rank }}
                    </button>
                } @else {
                    <!-- Hide favorite icon for ranked products because it won't show the correct status after updating -->
                    <app-product-favorite-icon
                      [product]="product"
                      class="product-favorite-icon">
                    </app-product-favorite-icon>
                }

                <a [routerLink]="supplierProductIdForRoute | supplierProductDetailsRouterLink">
                    <img
                      [src]="product.images | productDefaultImageUrl"
                      alt="Bild des Produktes" />
                </a>
            </div>
        </div>

        <div class="w-[50%] flex flex-col gap-2">
            <a [routerLink]="supplierProductIdForRoute | supplierProductDetailsRouterLink">
                <div class="flex flex-col gap-2 flex-grow">
                    <div>
                        <div>
                            {{ product?.name }}
                        </div>
                        <div class="eco">
                            @for (i of [1, 2, 3, 4]; track i) {
                                <mat-icon fontSet="material-icons-round">eco</mat-icon>
                            }

                            @for (i of [1]; track i) {
                                <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                            }
                        </div>
                    </div>

                    <div>
                        @if (hasVariants) {
                            <div class="mat-caption">
                                {{ product.variant_count }} Varianten
                            </div>
                        }

                        @if (product.price_unit_price_from) {
                            <div class="unit-price">
                                @if (hasMultipleUnitPrices) {
                                    ab
                                }
                                {{ product.price_unit_price_from | priceToHighestCurrencyValue | currency: (product.price_unit_price_currency_code_id | currencyCodeById | async)?.name }}
                            </div>
                        }
                        @if (product.price_billing_frequency_price_from) {
                            <div class="billing-frequency-price">
                                @if (hasMultipleBillingFrequencyPrices) {
                                    ab
                                }
                                {{ product.price_billing_frequency_price_from | priceToHighestCurrencyValue | currency: (product.price_billing_frequency_price_currency_code_id | currencyCodeById | async)?.name }}
                                {{ product.price_billing_frequency_id | billingFrequencyById | async }}
                            </div>
                        }
                    </div>
                </div>
            </a>

            @if (isShopActive) {
                <div>
                    <!-- Only show loading for non ranked products as only they can have more than one supplier product. -->
                    @let productIsLoading = (productIsLoading$ | async) && !rank;

                    <button
                      mat-raised-button
                      color="primary"
                      (click)="toShoppingCart()"
                      [disabled]="productIsLoading"
                      type="button">
                        <div class="flex gap-2 items-center">
                            <mat-icon>shopping_cart</mat-icon>
                            In den Einkaufswagen

                            @if (productIsLoading) {
                                <mat-progress-spinner
                                  diameter="20"
                                  mode="indeterminate" />
                            }
                        </div>
                    </button>
                </div>
            }
        </div>
    </div>
</div>
