<div class="flex justify-end items-center">
    <button
      mat-icon-button
      mat-dialog-close
      matTooltip="Abbrechen"
      class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="dialog-container mat-typography flex flex-col">
        <app-supplier-products-product-list
          [supplierProducts]="data.supplierProducts"
          [product]="data.product"
          (addToShoppingCart)="addToShoppingCart($event)"
          (navigateToSupplierProduct)="cancel()" />
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>
</mat-dialog-actions>
