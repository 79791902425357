<div
  matDialogTitle
  class="mat-headline-6">
    Alle Auswahlmöglichkeiten zu {{ data.product.name }}

    <button
      mat-icon-button
      mat-dialog-close
      matTooltip="Abbrechen"
      class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="dialog-container mat-typography flex flex-col gap-2">
        <app-product-variant-selection
          *ngIf="data.variants"
          [variants]="data.variants"
          [initialSelection]="initialSelection"
          [variantAttributes]="(productsQuery.variantAttributesForActiveProduct$ | async)"
          [loading]="supplierProductsQuery.isLoading$ | async"
          (selectVariant)="selectVariant($event)" />

        <ng-container *ngIf="selectedVariant">
            <app-supplier-products-product-list
              [supplierProducts]="selectedVariant.id | supplierProductsForProduct$ | async"
              [loading]="supplierProductsQuery.isLoading$ | async"
              [product]="selectedVariant"
              (addToShoppingCart)="addToShoppingCart($event)"
              (navigateToSupplierProduct)="cancel()" />
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>
</mat-dialog-actions>
