import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {SupplierProduct} from '../../../state/supplier-products/supplier-product.model';
import {
  SelectSupplierProductForShoppingCartDialogData
} from './types/select-supplier-product-for-shopping-cart-dialog-data';
import {
  SelectSupplierProductForShoppingCartDialogOutputData
} from './types/select-supplier-product-for-shopping-cart-dialog-output-data';

@Component({
  selector: 'app-select-supplier-product-for-shopping-cart-dialog',
  templateUrl: './select-supplier-product-for-shopping-cart-dialog.component.html',
  styleUrls: ['./select-supplier-product-for-shopping-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSupplierProductForShoppingCartDialogComponent {
  readonly data: SelectSupplierProductForShoppingCartDialogData = inject(MAT_DIALOG_DATA);

  private readonly matDialogRef = inject(MatDialogRef<SelectSupplierProductForShoppingCartDialogComponent, SelectSupplierProductForShoppingCartDialogOutputData>);

  addToShoppingCart(supplierProduct: SupplierProduct) {
    this.matDialogRef.close({supplierProduct} as SelectSupplierProductForShoppingCartDialogOutputData);
  }

  cancel() {
    this.matDialogRef.close();
  }
}
